header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 40px;
    background-color: #333;
    color: white;
    position: sticky;
    top: 0;
    z-index: 100;
  }
  
  nav ul {
    display: flex;
    list-style: none;
    gap: 20px;
  }
  
  nav ul li {
    display: inline;
  }
  
  nav ul li a {
    color: white;
    text-decoration: none;
    font-size: 1.1rem;
    transition: color 0.3s;
  }
  
  nav ul li a:hover {
    color: #61dafb; /* React blue */
  }
  
  @media (max-width: 300px) {
    nav ul {
      flex-direction: column;
      gap: 10px;
    }
  }
  