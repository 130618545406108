.skillsContainer {
    text-align: center;
    padding: 40px 20px;
    background-color: #f0f8ff;
    display: flex; 
    flex-direction: column; 
    align-items: center; 
}
  
.skillsTitle {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #333;
}
  
.skillsGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, auto);
    gap: 20px;
}
  
.skillCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s, box-shadow 0.2s;
    width: 200px;
}
  
.skillCard:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}
  
.skillImage {
    width: 100px;
    height: 100px;
    object-fit: contain;
    margin-bottom: 15px;
}
  
.skillName {
    font-size: 1.2rem;
    color: #555;
    margin: 0;
}

@media (max-width: 768px) {
    .skillCard {
        max-width: 100px; /* Shrinks the cards for smaller screens */
    }

    .skillImage {
        width: 70%; /* Shrinks the images proportionally */
    }
}

@media (max-width: 480px) {
    .skillCard {
        max-width: 50px; /* Shrinks the cards for smaller screens */
    }

    .skillImage {
        width: 100%; /* Shrinks the images proportionally */
    }
}

