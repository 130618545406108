.contact-section {
    padding: 40px;
    background-color: #f9f9f9;
    border-radius: 8px;
    max-width: 600px;
    margin: 0 auto;
    
  }
  
  .contact-section h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .contact-section p {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
  }
  
  input, textarea {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  
  button {
    display: inline-block;
    margin: 0 auto;
    width: 100%;
    padding: 10px;
    background-color: #61dafb;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #21a1f1;
  }

  .button-container{
    text-align:center; 
  }
  