.project {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  margin: 40px 0;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
  text-align: left;
  width: 100%;
}

.project:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.title {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #333;
}

.description, .tech {
  color: #161515;
  line-height: 1.5;
  margin-bottom: 15px;
  font-size: 1rem;
}

.link {
  display: inline-block;
  padding: 8px 12px;
  color: #61dafb;
  border: 1px solid #61dafb;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s, color 0.3s;
  margin:8px;
}

.link:hover {
  background-color: #61dafb;
  color: white;
}

.pageSpecificWidth {
  width: 60%;
}

.image{
  width: 100%; /* Fills the container */
  max-width: 1000px;
  height: auto; /* Fixed height for uniform sizing */
  max-height: 500px;
  object-fit: cover; /* Crops the image to fit the dimensions */
  border-radius: 8px;
}